import React from 'react';
import * as styles from './index.module.css';
import AboutImg from '../../assets/about.jpg';
import Fade from 'react-reveal/Fade';

const About = () => {
  return (
    <div className={styles.about} id="about">
      <div className={styles.content}>
        <div className={styles.text}>
          <Fade>
            <div>
              <h2>關於堂爺爺</h2>
              <p>
                海堂爺爺繼承家業，世代務農，與牽手阿香婆婆畢生忙碌於農務。日出而作，日落而息，是夫妻倆每日生活的最佳寫照。
              </p>
              <p>
                文旦對堂爺爺而言，是從小至今最熟悉、最親近的果樹。年幼時，左右鄰舍屋前及屋後都種有文旦樹，每天看著文旦樹隨一年四季變化而有不同感受。春天文旦花香圍繞、夏天文旦樹可乘涼、秋天享受採果豐收、冬天落葉剪枝當有機肥。
              </p>
              <p>
                堂爺爺對文旦樹照顧得無微不至，有如疼愛自己的小孩一樣，每棵文旦樹的成長狀況，堂爺爺都非常清楚。在堂爺爺悉心照顧下，文旦樹高大健康，鄰里無人能比。堂爺爺相信文旦樹健康，結出的果實才甜美，每年才有一定水準的產量，這樣才能讓老顧客每年能品嘗正牌的麻豆老欉文旦。
              </p>
            </div>
            <div>
              <h2>堂爺爺文旦園的經營理念</h2>
              <p>
                堂爺爺自民國60年代開始種植麻豆文旦，數十年來一直秉持的經營理念是 ─
                文旦果樹越是健康，越是老欉，就會越來越好吃。
                平時除了拔草，剪枝，施有機肥，灌溉外，也注重根部的保養，堅持不添加人工化合物，讓消費大眾能安心品嘗新鮮美味的麻豆文旦。堂爺爺文旦園的文旦樹，每棵皆是樹枝茂密，路人經過皆讚不絕口，堂爺爺文旦園文旦樹，不但正老欉，且在其用心栽培下，所產的文旦果粒軟、汁多、味香甜。
              </p>
              <div className={styles.imageWrapper}>
                <img src={AboutImg} className={styles.image} />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default About;
