import React from 'react';
import * as styles from './index.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MidAutumn from '../../assets/midAutumn.inline.svg';

const OrderNow = () => {
  return (
    <div className={styles.OrderNow} id="orderNow">
      <h2>馬上訂購</h2>
      <div className={styles.productWrapper}>
        <div className={styles.promotion}>
          <div className={styles.promotionName}>
            <div className={styles.midAutumnWrapper}>
              <MidAutumn className={styles.midAutumn} />
            </div>
          </div>
          <div>
            <p className={styles.highLight}>自 8/1 起中秋優惠組開放訂購中！</p>
            <p>中秋優惠組內含頂級文旦兩箱，共約 16 顆頂級麻豆老叢文旦</p>
            <p>一組原價 1700 元，活動期間特價 1500 元含運</p>
            <p>限量 200 組，賣完為止</p>
          </div>
        </div>
        <div className={styles.product}>
          <div className={styles.productName}>
            <p>頂級文旦</p>
          </div>
          <div>
            <p>一箱 850 元</p>
            <p>每箱約有 10 斤頂級文旦</p>
          </div>
        </div>
        <div className={styles.product}>
          <div className={styles.productName}>
            <p>特優文旦</p>
          </div>
          <div>
            <p>一箱 700 元</p>
            <p>每箱約有 10 斤特優文旦</p>
          </div>
        </div>
      </div>
      <div className={styles.infoItem}>
        <div className={styles.itemName}>
          <p>
            運費說明 <FontAwesomeIcon icon={['fas', 'truck']} />
          </p>
        </div>
        <div>
          <p>
            台灣本島：同一地點運送件數5件以下，每件運費以100元計；同一地點運送件數5件以上(含)，免運費優待。
          </p>
          <p>離島：運費視實際運送方式，另議。</p>
        </div>
      </div>
      <div className={styles.infoItem}>
        <div className={styles.itemName}>
          <p>
            訂購方式 <FontAwesomeIcon icon={['fas', 'phone']} />
          </p>
        </div>
        <div>
          <p>電話：0933-827-688、0912-564-456</p>
          <p>E-Mail：jackychen@lucenttex.com.tw</p>
          <p>Line：jacky5655</p>
        </div>
      </div>
      <div className={styles.infoItem}>
        <div className={styles.itemName}>
          <p>付款說明</p>
        </div>
        <div>
          <p>銀行：臺灣銀行營業部 (銀行代號:004)</p>
          <p>帳號：003002097528</p>
          <p>戶名：陳永興</p>
          <p>
            請於出貨前選擇以匯款或ATM轉帳方式付款。匯款或轉帳時請標示付款人，以方便查詢及核對訂購單。
          </p>
        </div>
      </div>
      <div className={styles.infoItem}>
        <div className={styles.itemName}>
          <p>訂單及付款確認</p>
        </div>
        <div>
          <p>
            由於文旦採收及出貨期間，農務繁忙，若要訂購或確認付款狀況，請於每日下午4點以後，感謝配合！
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderNow;
