// extracted by mini-css-extract-plugin
export var nav = "index-module--nav--2Dpa1";
export var container = "index-module--container--DH8Dc";
export var mainTitle = "index-module--mainTitle--D_sl6";
export var mobileMenuButton = "index-module--mobileMenuButton--2wdbu";
export var menu = "index-module--menu--1A7RG";
export var menuIcon = "index-module--menuIcon--1omdk";
export var mobileMenuItem = "index-module--mobileMenuItem--_JI-0";
export var mobileMenu = "index-module--mobileMenu--36SHo";
export var logo = "index-module--logo--3n68a";
export var menuItem = "index-module--menuItem--co3o_";