import React from 'react';
import {A, B, C, D, E, F, G, H, I, J, K, L, M} from '../../assets/gallery';
import * as styles from './index.module.css';
import ImageCard from '../ImageCard';
import Fade from 'react-reveal/Fade';

const Gallery = () => {
  return (
    <div className={styles.gallery} id="gallery">
      <h2 className={styles.heading}>文旦園寫真</h2>
      <div className={styles.imageCards}>
        <Fade>
          <ImageCard imgSrc={A.default} title={'修剪新芽及疏枝'} />
          <ImageCard imgSrc={B.default} title={'修剪新芽及疏枝'} />
          <ImageCard imgSrc={C.default} title={'清掃落葉'} />
          <ImageCard imgSrc={F.default} title={'人工拔草不使用除草劑'} />
          {/* <ImageCard imgSrc={G.default} title={'人工拔草不使用除草劑'} /> */}
          <ImageCard imgSrc={E.default} title={'滿園芬芳'} />
          {/* <ImageCard imgSrc={D.default} title={'滿園芬芳'} /> */}
          <ImageCard imgSrc={H.default} title={'剔除新芽'} />
          <ImageCard imgSrc={I.default} title={'結實累累的老欉文旦'} />
          <ImageCard imgSrc={J.default} title={'結實累累的老欉文旦'} />
          <ImageCard imgSrc={K.default} title={'存放於通風良好的倉庫'} />
          <ImageCard imgSrc={L.default} title={'經過篩選分級包裝'} />
          {/* <ImageCard imgSrc={M.default} title={'經過篩選分級包裝'} /> */}
        </Fade>
      </div>
    </div>
  );
};

export default Gallery;
