// extracted by mini-css-extract-plugin
export var OrderNow = "index-module--OrderNow--3OwZ8";
export var product = "index-module--product--2yeGE";
export var infoItem = "index-module--infoItem--1eTYl";
export var promotion = "index-module--promotion--1dcsI";
export var productName = "index-module--productName--1q7oW";
export var itemName = "index-module--itemName--4ypOm";
export var productWrapper = "index-module--productWrapper--28zTw";
export var promotionName = "index-module--promotionName--2b2Dx";
export var midAutumnWrapper = "index-module--midAutumnWrapper--24cHD";
export var midAutumn = "index-module--midAutumn--18khN";
export var highLight = "index-module--highLight--2Km4G";
export var breathe = "index-module--breathe--3KjTe";