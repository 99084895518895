import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../config/icons';
import * as styles from './index.module.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Fade from 'react-reveal/Fade';
import Logo from '../../assets/logo.inline.svg';

const menuHeight = 79;
const menuAmount = 3;
const scrollAnchorOffset = '90';

const NavBar = () => {
  const [mobileMenuHeight, setMobileMenuHeight] = useState('0px');

  const toggleMobileMenu = () => {
    setMobileMenuHeight((prev) =>
      prev === '0px' ? `${menuHeight * menuAmount}px` : '0px'
    );
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.container}>
        <AnchorLink href="#landScape" offset={scrollAnchorOffset}>
          <div className={styles.mainTitle}>
            <Logo className={styles.logo} />
          </div>
        </AnchorLink>
        <div>
          <ul className={styles.menu}>
            <Fade left>
              <li className={styles.menuItem}>
                <AnchorLink href="#about" offset={scrollAnchorOffset}>
                  認識堂爺爺
                </AnchorLink>
              </li>
              <li className={styles.menuItem}>
                <AnchorLink href="#gallery" offset={scrollAnchorOffset}>
                  文旦園寫真
                </AnchorLink>
              </li>
              <li className={styles.menuItem}>
                <AnchorLink href="#orderNow" offset={scrollAnchorOffset}>
                  馬上訂購
                </AnchorLink>
              </li>
            </Fade>
          </ul>
          <div onClick={toggleMobileMenu} className={styles.mobileMenuButton}>
            <FontAwesomeIcon icon={['fas', 'bars']} className={styles.menuIcon} />
          </div>
        </div>
      </div>
      <ul className={styles.mobileMenu} style={{height: mobileMenuHeight}}>
        <AnchorLink href="#about" offset={scrollAnchorOffset} onClick={toggleMobileMenu}>
          <li className={styles.mobileMenuItem}>認識堂爺爺</li>
        </AnchorLink>
        <AnchorLink
          href="#gallery"
          offset={scrollAnchorOffset}
          onClick={toggleMobileMenu}>
          <li className={styles.mobileMenuItem}>文旦園寫真</li>
        </AnchorLink>
        <AnchorLink
          href="#orderNow"
          offset={scrollAnchorOffset}
          onClick={toggleMobileMenu}>
          <li className={styles.mobileMenuItem}>馬上訂購</li>
        </AnchorLink>
      </ul>
    </nav>
  );
};

export default NavBar;
