export const A = require('./A.jpg');
export const B = require('./B.jpg');
export const C = require('./C.jpg');
export const D = require('./D.jpg');
export const E = require('./E.jpg');
export const F = require('./F.jpg');
export const G = require('./G.jpg');
export const H = require('./H.jpg');
export const I = require('./I.jpg');
export const J = require('./J.jpg');
export const K = require('./K.jpg');
export const L = require('./L.jpg');
export const M = require('./M.jpg');
