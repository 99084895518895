import React from 'react';
import * as styles from './index.module.css';
import PropTypes from 'prop-types';

const ImageCard = (props) => {
  const {imgSrc, title} = props;

  return (
    <div className={styles.imageCardWrapper}>
      <div className={styles.imageCard}>
        <div className={styles.imageWrapper}>
          <img src={imgSrc} className={styles.image} alt={title} />
        </div>
        <div className={styles.titleWrapper}>
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ImageCard;
