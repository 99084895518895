import * as React from 'react';
import './reset.css';
import * as styles from './index.module.css';
import NavBar from '../components/NavBar';
import LandScape from '../components/LandScape';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
import OrderNow from '../components/OrderNow';
import { Helmet } from 'react-helmet';

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>堂爺爺文旦園 - 麻豆老叢文旦園</title>
        <meta name="description" content="堂爺爺自民國60年代開始種植文旦，文旦樹高大健康是正港的麻豆老欉文旦樹，堂爺爺的文旦果粒軟、汁多、味香甜，是您中秋送禮的最佳選擇！" />
        <meta property="og:url" content="https://hido.tw/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="堂爺爺文旦園 - 麻豆老叢文旦園" />
        <meta property="og:image" content="https://hido.tw/static/ogimage.jpg" />
        <meta property="og:description" content="堂爺爺自民國60年代開始種植文旦，文旦樹高大健康是正港的麻豆老欉文旦樹，堂爺爺的文旦果粒軟、汁多、味香甜，是您中秋送禮的最佳選擇！" />
      </Helmet>
      <NavBar />
      <LandScape />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <About />
          <Gallery />
          <OrderNow />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
