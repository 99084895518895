import React from 'react';
import * as styles from './index.module.css';

const Footer = () => {
  return (
    <div className={styles.footer} id="footer">
      <div className={styles.content}>
        <div className={styles.contact}>
          <p>電話：0933-827-688、0912-564-456</p>
          <p>E-Mail：jackychen@lucenttex.com.tw</p>
        </div>
        <div className={styles.copyRight}>
          <p>
            © 2021 made with ❤ by Aislyn Y.C. & Ying-Jhe Hu. All rights reserved. Icon
            credit to fontawesome.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
